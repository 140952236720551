/* eslint-disable import/no-anonymous-default-export */

import axios from "axios"
import { CONCERT_FORM_API } from "./api"
import DAUserModel from "./models"
import { API_BASEURL } from "./settings"


const concertFormAPI = (params) => {
   const url = `${API_BASEURL + CONCERT_FORM_API + "/"}`
   return new Promise((resolve) => {
      axios
         .post(`${url}`, params, {
            headers: {
               "Content-Type": "application/json",
            },
         })
         .then((response) => {
            // console.log(response);
            const parsedResult = DAUserModel.parseDefault(response)
            resolve(parsedResult)
         })
         .catch((e) => {
            const parsedResult = DAUserModel.parseErrorDefault(e.response)
            resolve(parsedResult)
         })
   })
}


export default {
   concertFormAPI,
}