    //Common-Styles
    import styled from 'styled-components';
    import CheckboxTickIcon from '../assets/images/checkboxtick.png';

    export const MainContainer = styled.div`
        width:100%;
        height:100%;
        .displayflex {display:flex;}
        .displayinlineblock {display:inline-block;}
        .displayblock {display:block;}
        .displaynone {display:none;}
        .displaytable {display:table;}
        .displaytablecell {display:table-cell;}
    `
    export const BackgroundImg = styled.span`
        background-color: var(--check-bg-color);
        width:24px;
        height:24px;
        display:inline-block;
        background-size:24px;
        border-radius:4px;
    `
    export const SubContainer = styled.div`
        height:100%;
        margin:0 auto;
        position:relative;

        /*  Desktop View  */
        @media (min-width:768px){
        max-width:640px;
        }

        /*  Tablet View  */
        @media (min-width:481px) and (max-width:767px){max-width:540px;}

        /*  Mobile View  */
        @media (min-width:320px) and (max-width:480px){max-width:100%;}    
        `

    export const Main = styled.div`    


    &.basic-tier{
        background:var(--basic-tier);
        position:relative;
        z-index:1;
    }

    &.plus-tier{
        background:var(--plus-tier);
        position:relative;
        z-index:1;
    }

    &.premium-tier{
        background:var(--premium-tier);
        position:relative;
        z-index:1;
    }

    &.ultra-tier{
        background:var(--ultra-tier);
        position:relative;
        z-index:1;
    }

    
    &.w-33{
            width:33%;
        }
    `

    export const Section = styled.div`
        width:100%;display:inline-block;
         &.bar_vw_prof {
            width:auto;
         }
         &.article_in {
            width:auto;
         }
         &.w-in-auto {
            width:auto;
            padding:15px;
         }
         &.news-auto {
            width:auto;
         }
         &.notificaion_list {
            border-bottom:1px solid var(--border-notify-color);
         }
    `
    export const Textarea = styled.textarea`
        width:-webkit-fill-available;
        font-size:16px; 
        border:1px solid #252622;
        background-color:#252622;
        color:#ffffff;
        box-shadow:none;
        padding:14px 14px 14px 14px;
        height:123px;
        resize:none;
        outline:0 none;
        box-shadow:none;
        border-radius:6px;
        &.fill_account_txt {
          padding:14px 50px 14px 14px;
          font-size:14px;
           &::-webkit-input-placeholder {
            color: var(--white-color);
            opacity:1;
           }
           &::-moz-placeholder {
              color: var(--white-color);
              opacity:1;
           }
           &:-ms-input-placeholder {
              color: var(--white-color);
              opacity:1;
           }
           &:-moz-placeholder {
              color: var(--white-color);
              opacity:1;
           }
        }
    `
    export const Input = styled.input`
        width:-webkit-fill-available;
        font-size:14px; 
        border:1px solid var(--bg-input-field);
        background-color:var(--bg-input-field);
        color:#ffffff;
        box-shadow:none;
        padding:14px 14px 14px 60px;
        border-radius:6px;

        &.input-no-pad{
          padding-left:14px;
          }

         &.password-right{
          padding-right:60px;
         } 

          &:-webkit-autofill,
          &:-webkit-autofill:hover, 
          &:-webkit-autofill:focus, 
          &:-webkit-autofill:active  {
            transition: background-color 5000s;
            -webkit-text-fill-color:var(--white-color) !important;
          }
    
        &::placeholder{
          color:#fafafa4d;
        }

        &:focus{
          border-color:#474845;
          outline:none;
        }
        &.full_inname {
          padding:14px 14px 14px 50px;
        }
        &.birth_date {
          padding:14px 50px 14px 14px;
        } 
        &.inputcheck {
          width:24px;
          height:24px;
        }
        &.locationcheck {
          opacity:0;
        }
        &.locationcheck:checked ~ span {
          background-image: url(${CheckboxTickIcon});
          background-color: var(--transparent);
        } 
        &.list_check_fill {
          width:100%;
          height:100%;
        }
        &.locationcheck ~ .add_checktick {
          background-color:var(--transparent);
        }
        &.full_lg_search {
          padding:14px 14px 14px 45px;
        }
        &.fill_account {
          padding:14px 50px 14px 50px;
          font-size:14px;
           &::-webkit-input-placeholder {
            color: var(--white-color);
            opacity:1;
           }
           &::-moz-placeholder {
              color: var(--white-color);
              opacity:1;
           }
           &:-ms-input-placeholder {
              color: var(--white-color);
              opacity:1;
           }
           &:-moz-placeholder {
              color: var(--white-color);
              opacity:1;
           }
        }
        &.switchin {
            opacity: 0;
            width: 0;
            height: 0;
            &:checked + .sliders {
                background-color:var(--articlelarge-bg-color);
            }
            &:checked + .sliders:before {
              -webkit-transform: translateX(20px);
              -ms-transform: translateX(20px);
              transform: translateX(20px);
            }
        }          
    `
    export const LinkTag = styled.a`font-size:18px;cursor:pointer;text-decoration:none;`
    export const Image = styled.img`
     object-fit:contain;
     &.bar_prof {
        object-fit: cover;
        border-radius: 45% 0 0 40%;
        position: relative;
        left: 0;
        top: -10px;
     }
     &.profile_img {
        object-fit:cover;
        width:120px;
        height:120px;
        border-radius:100%;
     }
     &.qrcodeImg {
      width: 80%;
      height: 100%;
     }
    `
    export const LeftArrow = styled.img` width:30px;`


    export const DefaultButton = styled.button`
    width:100%;
    background-color:  ${({red}) => (red && 'red') || '#2D3A42'};
    color:#ffffff;
    border-radius:4px;
    padding:12px 24px 12px 24px;
    font-size:14px;
    height:48px;
    box-shadow:none;
    border:none;
    cursor:pointer;

    &.disable{
        opacity:0.5;
        cursor: no-drop;
    } 

    &.w-auto{
        width:auto;
    }

    &.absolute{
        position:absolute;
    }

    &.bottom-20{
        bottom:20px;
    }

    &.left-20{
        left:20px;
    }

    &.right-20{
        right:20px;
    }

    &.earn-btn{
        background-color:var(--bg-vip-btn);
        color:var(--white-color);
        opacity:0.80;
        font-size:12px;
    }
    &.vip_premium_btn {
        background-color:var(--bg-perks);
        color:var(--white-color);
        opacity:0.50;
        font-size:12px;
        padding:0 12px;
        height:48px;
    }

    &.point_history{
        height:38px;
        background: transparent;
        border:1px solid var(--border-transparent-color);
        border-radius:30px;
        padding:10px 18px;
    }

    &.EventPoints{
        height:34px;
        background: transparent;
        font-size:12px;
        background:var(--bg-input-field);
        border:1px solid var(--border-updates-color);
        border-radius:30px;
        padding:8px 10px;
        letter-spacing:1px;
    }

    &.vault_scroll_Btn{
        width:auto;
        font-size:12px;
        padding:15px 12px 15px 12px;
        background:var(--bg-perks);
        color:var(--gray-color);
    }

    &.skipin {
        background:var(--bg-transparent-color);
        border:1px solid var(--border-transparent-color);
    }
    &.custom_bottom {
        padding: 12px 15px 12px 15px;
    }
    &.cancel_wd {
        width:60%;
    }
    &.premium_btn {
        padding:8px 17px;
        width:auto;
        color:var(--white-color);
        border:1px solid var(--border-transparent-color);
        background-color:var(--transparent);
        border-radius:999px;
        font-size:14px;
        font-weight:400;
        font-family:var(--font-Regular);
        height:auto;
        margin:0;
    }
    &.disable_dark[disabled] {
        opacity: 0.50;
        color:var(--disable-color);
        cursor: auto;
    }
    &.need_dash {
        color:var(--point-gray-color);
    }
`

