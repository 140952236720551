import React from 'react';
import styled from 'styled-components';

// Styled components for the error popup
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const Popup = styled.div`
  display: flex;
  width: 328px;
  height: 269px;
  padding: 32px 12px 12px 12px;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  border-radius: 4px;
  border: 1px solid rgba(90, 90, 90, 0.25);
  background: #191A17;
  text-align: center;
`;

const Message = styled.p`
  font-size: 16px;
  color: white;
  margin: 8px 0;
`;

const CloseButton = styled.button`
  background: #e74c3c;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  margin-top: 16px;

  &:hover {
    background: #c0392b;
  }
`;

const ErrorPopup = ({ message, onClose }) => {
  return (
    <Overlay>
      <Popup>
        <Message>{message}</Message>
        <CloseButton onClick={onClose}>Close</CloseButton>
      </Popup>
    </Overlay>
  );
};

export default ErrorPopup;
