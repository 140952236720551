import React from "react";
import { Input, Image, DefaultButton } from "../components/part1Styles";
import emailIcon from '../part1Assets/icons/email.svg';
import personIcon from "../part1Assets/icons/accountIcon.svg";
import userApi from "../utils/userApi2";
import SuccessPopup from "../part1Assets/successPop"; 
import ErrorPopup from "../part1Assets/errorPop";
import styled from 'styled-components';

import Test from "../part1Assets/images/bkg2.jpg";

// background: radial-gradient(circle, rgba(28,36,32,1) 0%, rgba(17,24,19,1) 50%, rgba(17,24,19,1) 100%);
const MainContainer2 = styled.div`
  width: 100%;
  height: 100vh; 
  opacity: 0.9;
  background-color: #00aeef;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; 
  position: relative;
`;

const ImageContainer = styled.div`
  width: 90vw;
  position: relative;
  margin-top: 10px;
`;

const BackgroundImage = styled.img`
  width: 100%;

`;

const SubContainer = styled.div`
height: 54vh;
  border-radius: 4px;
  border: 1px solid rgba(90, 90, 90, 0.25);
  background-color: #00aeef;
  backdrop-filter: blur(6px);
  padding: 20px;
  width: 100%;
  max-width: 90vw; 

  @media (max-width: 640px) {
    padding: 10px;
  }
`;

const Section = styled.div`
  width: 100%;
  display: inline-block;
  text-align: center; 
`;

const Heading = styled.h1`
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; 
`;

const Subheading = styled.h1`
  color: #FFF;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;

  a {
    color: inherit; /* Ensures the link color is the same as the subheading color */
    text-decoration: none; /* Removes the underline */
    
    &:hover {
      text-decoration: underline; /* Optionally, underline on hover */
    }
  }
`;

function Form() {
    const [error, setError] = React.useState(""); // State for error messages
    const [success, setSuccess] = React.useState(""); // State for success messages
    const [formData, setFormData] = React.useState({
        name: "",
        email: ""
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
        console.log("Form data updated:", formData); // Log form data changes
    };

    const handleSubmit = () => {
        const params = {
            name: formData.name,
            email: formData.email,
        };

        console.log("Submitting form with params:", params);

        userApi.concertFormAPI(params)
            .then((res) => {
                console.log("API response:", res);

                // Check if the response contains a success status
                if (res.status === true) {  
                    console.log("Success: User created successfully");
                    setSuccess("Your Motley Crue collectibles will be handed out soon. Look out for an email from us.");
                    setError("");  // Clear error if success
                } else {
                    // Set error for email already in use
                    console.log("Error: Email already in use");
                    setError("Email already in use.");
                    setSuccess("");  // Clear success state
                }
            })
            .catch((err) => {
                console.log("API Error:", err);
                setError("An error occurred while submitting the form. Please try again.");
                setSuccess("");
            });
    };

    const closePopup = () => {
        console.log("Closing popups"); 
        setSuccess(""); // Reset success state
        setError(""); // Reset error state
    };

    return (
        <MainContainer2>
            {success ? (
                <SuccessPopup message={success} onClose={closePopup} />
            ) : error ? (
                <ErrorPopup message={error} onClose={closePopup} />
            ) : (
                <>
                    <ImageContainer>
                        <BackgroundImage src={Test} alt="background" />
                    </ImageContainer>

                    <SubContainer>
                        <Heading>Get a piece of the Action! Join the S.I.N. Club for first access to Mötley news, Tickets and more...</Heading>
                        <Subheading>Click <a href="https://www.crueseum.com/" target="_blank" rel="noopener noreferrer"><u>HERE</u></a> to check out The Crueseum and the 35th anniversary of the Dr. Feelgood collection!</Subheading>
                        <Section className="mb-20">
                            <Section className="relative">
                                <Image src={personIcon} className="absolute w-auto top-25 left-20" />
                                <Input className="inputBg mt-10 fontMedium" placeholder="Name" name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    autoComplete="off"
                                />
                            </Section>

                            <Section className="relative">
                                <Image src={emailIcon} className="absolute w-auto top-25 left-20 " />
                                <Input className="inputBg mt-10 fontMedium" placeholder="Email" name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    autoComplete="off"
                                />
                            </Section>
                        </Section>

                        <DefaultButton onClick={handleSubmit} disabled={!(formData.name && formData.email)}>
                            Submit
                        </DefaultButton>
                    </SubContainer>
                </>
            )}
        </MainContainer2>
    );
}

export default Form;